import React from "react";
import "./programs.css";
import baby_5 from "../../assets/baby_5.JPG";
import boy_6 from "../../assets/boy_6.JPG";
import girl_8 from "../../assets/girl_8.JPG";
import girl_6 from "../../assets/girl_6.jpg";
import girl_9 from "../../assets/girl_9.JPG";

export default function Programs() {
  return (
    <div className="programs-container">
      <div className="programs-list">
        <div className="program">
          <div>
            <img className="program-img right" src={baby_5} alt="Toddler"></img>
          </div>
          <div>
            <h2 className="program-title toddlers">Toddlers</h2>
            <h3 className="program-desc">
              Our toddler classroom is a lively and engaging environment where
              our trained teachers provide attentive and patient care. The
              children, both Wobblers (1-year-olds) and Toddlers, are encouraged
              to explore and learn. We offer a range of activities, including
              art, movement, storytime, and outdoor discovery. <br />
              <br /> The Wobblers have a separate space with a maximum of six
              children to ensure a smooth transition. Interactions between the
              two groups occur during enrichment classes. Our playground
              features various fun elements like a sandbox, water tables,
              climbing structures, and painting easels. Parents receive a Daily
              Report with details about their child's day, including food,
              diapering, and nap times.
            </h3>
          </div>
        </div>
        <div className="program reverse">
          <div>
            <h2 className="program-title twos">Twos</h2>
            <h3 className="program-desc">
              In our Twos classroom, children thrive with blossoming language
              skills, increased social abilities, and imaginative play. They
              explore their surroundings, engage in pretend play, build, create,
              and socialize. Potty training occurs when they're ready.
              Activities include story time, circle time, life skills, themed
              units, LIM lessons, yoga, aerobics, sign language, Spanish, music,
              and interest centers. Please label your child's items, excluding
              bottles and pacifiers. Daily reports will continue until the end
              of this year.
            </h3>
          </div>
          <div>
            <img
              className="program-img left"
              src={boy_6}
              alt="Two Year Old"
            ></img>
          </div>
        </div>
        <div className="program">
          <div>
            <img
              className="program-img right"
              src={girl_8}
              alt="Three Year Old"
            ></img>
          </div>
          <div>
            <h2 className="program-title threes">Threes</h2>
            <h3 className="program-desc">
              Our Threes classroom encourages cooperative play, teamwork, and
              creativity. Children enjoy group lessons, center time, and outdoor
              experiences. Friendships form, and themed curriculum deepens their
              curiosity. They explore books, letters, and sounds together. They
              reach social, language, cognitive, and physical milestones. Circle
              time fosters turn-taking, public speaking, and letter and number
              recognition. Potty training is required for this classroom.
            </h3>
          </div>
        </div>
        <div className="program reverse">
          <div>
            <h2 className="program-title vpk">VPK</h2>
            <h3 className="program-desc">
              Learning in Motion is proud to offer an exceptional VPK program
              with top-ranking testing scores in Cooper City and Broward County.
              Our small classrooms and low teacher-to-child ratio provide an
              inclusive learning environment. With enrichment classes, field
              trips, and the Frog Street curriculum, we foster early language
              and literacy skills. Family events, extended care, homework,
              assessments, and parental involvement opportunities are integral
              to our program. Rest assured, your child will be well-prepared for
              kindergarten with our comprehensive approach. <br />
              <br />
              Our Summer VPK program features the "Let's Begin with the Letter
              People" curriculum, an engaging 8–10 week, all-day program. This
              program is FREE for children who qualify and provides a fun and
              educational way to continue learning throughout the summer.
            </h3>
          </div>
          <div>
            <img
              className="program-img left"
              src={girl_6}
              alt="Little Girl"
            ></img>
          </div>
        </div>
        <div className="program">
          <div>
            <img
              className="program-img right"
              src={girl_9}
              alt="Girl Painting"
            ></img>
          </div>
          <div>
            <h2 className="program-title camp">Summer Camp</h2>
            <h3 className="program-desc">
              Join us for a fun-filled summer! Our small camp blends learning,
              development, and summertime fun. Children make lifelong
              friendships and learn valuable social skills while enjoying a
              relaxing and educational experience. Our highly skilled teachers,
              with years of experience, create a low teacher-to-child ratio. We
              incorporate fun educational lessons twice a week to retain school
              year knowledge. Open to 4-year-olds starting VPK in the fall. No
              special attire required, and lunch is not provided. Give your
              child an unforgettable summer!
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
