import React from "react";
import Marquee from "react-fast-marquee";
import "./marquee.css";

export default function MarqueeBanner() {
  return (
    <div className="marquee">
      <Marquee speed={80} direction="right">
        <span>Free All-Day Summer VPK Program – Enroll Now!</span>
      </Marquee>
    </div>
  );
}
