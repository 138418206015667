import React from "react";
import "./header.css";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import slide_1 from "../../assets/girl_10.JPG";
import slide_2 from "../../assets/sandbox_large.JPG";
import slide_3 from "../../assets/kids_3.JPG";
import logobig from "../../assets/LIM Logo BIG.png";

export default function Header() {
  return (
    <div className="header-container">
      <Fade
        cssClass="fade"
        indicators={false}
        onChange={function noRefCheck() {}}
        onStartChange={function noRefCheck() {}}
        arrows={false}
        infinite={true}
        duration={6000}
        transitionDuration={1500}
      >
        <div className="each-slide">
          <div>
            <img className="slide" alt="Girl blowing bubbles" src={slide_1} />
          </div>
        </div>
        <div className="each-slide">
          <div>
            <img className="slide" alt="Sandbox" src={slide_2} />
          </div>
        </div>
        <div className="each-slide">
          <div>
            <img className="slide" alt="Children drawing" src={slide_3} />
          </div>
        </div>
      </Fade>
      <img
        className="school-name"
        src={logobig}
        alt="Learning in Motion Preschool"
      ></img>
      {/* <a href="/#" className='slogan'>Learning Anchored By Movement</a> */}
    </div>
  );
}
